import React from 'react';
import { Link } from 'gatsby';
import { Image } from '@alterpage/gatsby-plugin-image';

import {
    wrapper,
    dateText,
    categoryText,
    titleText,
    paragraph,
    imageBox,
    image,
    ratio,
    redirectButton,
    tagList,
    tagItem,
    tagText,
    authorBox,
} from './post-card.module.scss';

import {relations} from "../../config/relations";
import { IPostCard } from '../../models/post.model';
import useTranslations from '../../hooks/use-translations';

import Button from '../atoms/button';
import Avatar from '../atoms/avatar';

interface IBlogCardProps {
    className?: string;
    postCard: IPostCard;
    TitleTag?: React.ElementType;
}

const PostCard: React.FC<IBlogCardProps> = ({ className = '', postCard, TitleTag = 'h2' }) => {
    const { media, pathname, mainCategory, title, lead, displayPublishedAt, tags, author } =
        postCard;
    const t = useTranslations('PostCard');

    return (
        <Link to={pathname} className={`${wrapper} ${className} post-card`}>
            <div className={`${imageBox} post-card__image-box`}>
                <Image className={`${image} post-card__image`} media={media} ratioClass={ratio} relation={relations.thumbnailImage} />
            </div>
            <time className={`${dateText} post-card__date`}>{displayPublishedAt}</time>
            <TitleTag className={`${titleText} post-card__title`}>{title}</TitleTag>
            {author && (
                <div className={`${authorBox} post-card__author`}>
                    <Avatar className="post-card__avatar" media={author.media} />
                    <p className="post-card__author-name">{author.displayName}</p>
                </div>
            )}
            {mainCategory && (
                <p className={`${categoryText} post-card__category-name`}>{mainCategory.title}</p>
            )}
            {lead && <p className={`${paragraph} post-card__lead`}>{lead}</p>}
            {tags && tags.length > 0 && (
                <ul className={`${tagList} post-card__tag-list`}>
                    {tags.map((tag) => {
                        return (
                            <li
                                key={`tag-item-${tag.tagId}`}
                                className={`${tagItem} post-card__tag-item`}
                            >
                                <p className={`${tagText} post-card__tag-name`}>{tag.name}</p>
                            </li>
                        );
                    })}
                </ul>
            )}
            <Button
                className={`${redirectButton} post-card__button`}
                context="postCard"
                as="element"
            >
                {t.readmore}
            </Button>
        </Link>
    );
};

export default PostCard;
